<template>
  <header class="header" id="top">
    <div class="header__menu"  :class="cls">
      <router-link to="/" class="logo__link" />
    </div>
  </header>
</template>
<script>
export default {
  props: {
    cls: {
      type: String,
      default: 'header__menu--login',
    },
  },
};
</script>
